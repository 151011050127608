import { type VariantProps, cva } from 'class-variance-authority';
import { Check } from 'lucide-react';
import * as React from 'react';
import { cn } from '@/lib/utils';
const badgeVariants = cva('inline-flex gap-2 items-center rounded-full text-ellipsis whitespace-nowrap overflow-hidden border border-zinc-200 px-4 py-1 font-mono transition-colors focus:outline-none focus:ring-2 focus:ring-zinc-950 focus:ring-offset-2', {
  variants: {
    variant: {
      active: 'inline-flex py-[0.05rem] border-secondary bg-secondary text-white hover:bg-[#003de7e6]',
      default: 'border-secondary bg-[#acc2ff47] text-secondary hover:bg-[#acc2ffe6]',
      destructive: 'border-transparent bg-red-500 text-zinc-50 hover:bg-red-500/80',
      outline: 'text-zinc-950'
    },
    size: {
      default: 'text-sm',
      xs: 'text-xs',
      sm: 'text-sm',
      md: 'text-base',
      lg: 'text-lg'
    }
  },
  defaultVariants: {
    variant: 'default'
  }
});
export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {}
function Badge({
  className,
  size,
  variant,
  ...props
}: BadgeProps) {
  return <div className={cn(badgeVariants({
    variant,
    size
  }), className)} {...props}>
      {variant === 'active' && <Check className="shrink-0 text-[#648dff]" />}
      {props.children}
    </div>;
}
export { Badge, badgeVariants };