import { Slot } from '@radix-ui/react-slot';
import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';
import { cn } from '@/lib/utils';
const buttonVariants = cva('inline-flex uppercase items-center justify-center whitespace-nowrap rounded-md text-base font-[800] ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-950 focus-visible:ring-offset-2 disabled:text-slate-300 disabled:pointer-events-none', {
  variants: {
    variant: {
      default: 'flex shadow-lg gap-2 font-heading border border-primary-readable/[0.25] bg-primary text-white hover:bg-[#4f0063]',
      destructive: 'flex shadow-lg gap-2 font-heading bg-red-500 text-zinc-50 border border-transparent hover:border-red-700 hover:bg-red-500/90',
      outline: 'flex shadow-lg gap-2 font-heading border border-zinc-200 bg-background hover:border-secondary/[0.25] hover:bg-zinc-100 hover:text-zinc-900',
      secondary: 'flex shadow-lg gap-2 font-heading border bg-secondary text-white hover:bg-secondary-readable',
      ghost: 'flex gap-2 hover:bg-[#72008e0a]',
      link: 'font-heading text-zinc-900 underline-offset-4 hover:underline'
    },
    size: {
      default: 'h-10 px-2 md:px-4 py-3 text-base',
      xs: 'h-6 rounded-md py-4 px-3 text-xs',
      sm: 'h-9 rounded-md py-3 px-3 text-sm',
      lg: 'h-11 rounded-md py-3 px-8 text-lg',
      icon: 'h-10 w-10'
    }
  },
  defaultVariants: {
    variant: 'default',
    size: 'default'
  }
});
export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
  className,
  variant,
  size,
  asChild = false,
  ...props
}, ref) => {
  const Comp = asChild ? Slot : 'button';
  return <Comp className={cn(buttonVariants({
    variant,
    size,
    className
  }))} ref={ref} {...props} />;
});
Button.displayName = 'Button';
export { Button, buttonVariants };