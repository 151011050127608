import { SearchParams } from './types/SearchParams';

export const PAGE_DEFAULT = 0;
export const ROWS_DEFAULT = 15;
export const SORT_DEFAULT = 'pkg_score desc';

export const ROW_OPTIONS = [5, 10, 15, 25, 50] as const;
export const SORT_DIRECTIONS: ('asc' | 'desc')[] = ['asc', 'desc'];

export const SORT_DIRECTION_LABELS = {
  asc: 'ascending',
  desc: 'descending',
};

export const DEFAULT_SEARCH_PARAMS = {
  page: PAGE_DEFAULT.toString(),
  rows: ROWS_DEFAULT.toString(),
  sort: SORT_DEFAULT.toString(),
  query: '',
  source_en: '',
  country_en: '',
  tags: [''],
};

export const calculateSearchStart = (page?: number, rows?: number) =>
  (page ?? 0) * (rows ?? 25);

export const createUrlSearchParamsFromSearchParams = (
  searchParams: SearchParams,
) => {
  const searchUrlParams = new URLSearchParams();
  for (const [key, value] of Object.entries(searchParams)) {
    searchUrlParams.set(key, value);
  }

  if (!searchParams.query) {
    searchUrlParams.delete('query');
  }

  if (!searchParams.country_en) {
    searchUrlParams.delete('country_en');
  }

  if (!searchParams.source_en) {
    searchUrlParams.delete('source_en');
  }

  if ((searchParams.tags?.length ?? 0) <= 0) {
    searchUrlParams.delete('tags');
  }

  return searchUrlParams;
};
