'use client';

import classes from '@/components/Search/SearchResults/classes';
import type { SearchResult } from '@/lib/types/SearchResult';
import { SearchResultsListItem } from './ListItem';
interface SearchResultListProps extends Pick<SearchResult, 'result_count' | 'results'> {}
export function SearchResultList({
  result_count,
  results
}: SearchResultListProps) {
  return <div className={classes.list}>
      {result_count > 0 && results.map(dataset => <SearchResultsListItem key={dataset.id} item={dataset} map={`${process.env.NEXT_PUBLIC_APP_URL}/api/map/${dataset.id}`} />)}
    </div>;
}