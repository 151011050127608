'use client';

import { MotionProps, motion } from 'framer-motion';
import { ComponentProps } from 'react';
interface RevealProps {
  children: React.ReactNode;
}
export const Reveal = ({
  children,
  ref,
  ...props
}: ComponentProps<'div'> & MotionProps & RevealProps) => <motion.div {...props} initial={{
  opacity: 0
}} whileInView={{
  opacity: 1
}}>
    {children}
  </motion.div>;