'use client';

import classes from '@/components/Search/SearchResults/classes';
import { SearchParams } from '@/lib/types/SearchParams';
import type { SearchResult } from '@/lib/types/SearchResult';
import { SortDictionary } from '@/lib/types/SortOption';
import { SearchResultsHeader } from './Header';
import { SearchResultsControls } from './Header/Controls';
import { SearchResultList } from './List';
import { NoResults } from './NoResults';
interface SearchResultsProps {
  result: SearchResult | null;
  params: SearchParams;
  sortOptions: SortDictionary;
}
export function SearchResults({
  result,
  params,
  sortOptions
}: SearchResultsProps) {
  const {
    results,
    result_count
  } = result ?? {};
  const hasSearchResults = (results?.length ?? 0) > 0 && (result_count ?? 0) > 0;
  return <div className={classes.wrapper}>
      <div className={classes.content}>
        <SearchResultsHeader sortOptions={sortOptions} result={result} params={params} />
        {!hasSearchResults && <NoResults />}
        {result && <SearchResultList {...result} />}

        <div className={classes.controls}>
          <SearchResultsControls result_count={result?.result_count ?? 0} params={params} />
        </div>
      </div>
    </div>;
}