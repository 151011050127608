import { SearchX } from 'lucide-react';
import Link from 'next/link';
import { InfoAlert } from '@/components/Shared/InfoAlert';
import { DEFAULT_SEARCH_PARAMS } from '@/lib/search';
export function NoResults() {
  const {
    query,
    page
  } = DEFAULT_SEARCH_PARAMS;
  return <>
      <div className="prose w-full max-w-full">
        <InfoAlert icon={<SearchX />} title="No results">
          <>
            <div>
              <p>We couldn&apos;t find any results for your query.</p>
              <ul className="[&_*]:text-sm">
                <li>Check your query for typos and spelling errors</li>
                <li>Try more general keywords</li>
                <li>Try different keywords</li>
              </ul>

              <Link scroll={false} prefetch={false} href={{
              pathname: '/search',
              query: new URLSearchParams({
                query,
                page
              }).toString()
            }}>
                Reset search
              </Link>
            </div>
          </>
        </InfoAlert>
      </div>
    </>;
}