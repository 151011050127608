'use client';

import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useCallback } from 'react';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { PAGE_DEFAULT, SORT_DEFAULT, SORT_DIRECTIONS, SORT_DIRECTION_LABELS } from '@/lib/search';
import { SortDictionary } from '@/lib/types/SortOption';
import { DirectionIcon } from './DirectionIcon';
interface SearchSortOptionsProps {
  sortOptions: SortDictionary;
}
const arrayFromSortOptions = (sortOptions: SortDictionary) => Array.from(Object.entries(sortOptions), ([name, value]) => ({
  name,
  value
}));
export function SearchSortOptions({
  sortOptions
}: SearchSortOptionsProps) {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const router = useRouter();
  const sortOptionArray = arrayFromSortOptions(sortOptions);
  const createQueryString = useCallback((name: string, value?: string | null) => {
    const params = new URLSearchParams(searchParams);
    if (value) {
      params.set('page', PAGE_DEFAULT.toString());
      params.set(name, value);
    } else {
      params.delete(name);
    }
    return params.toString();
  }, [searchParams]);
  return <div>
      <Label htmlFor="sort">Sort</Label>
      <Select onValueChange={value => router.push(pathname + '?' + createQueryString('sort', value))} defaultValue={searchParams.get('sort') ?? SORT_DEFAULT}>
        <SelectTrigger id="sort" className="w-full sm:w-64">
          <SelectValue placeholder="Sort" />
        </SelectTrigger>
        <SelectContent>
          {sortOptionArray.map(item => [...SORT_DIRECTIONS.map(direction => <SelectItem key={`${item.name}_${direction}`} value={`${item.name} ${direction}`} title={`${item.value.en}, ${SORT_DIRECTION_LABELS[direction]}`}>
                <div>
                  {item.value.en} <DirectionIcon direction={direction} />
                </div>
              </SelectItem>)])}
        </SelectContent>
      </Select>
    </div>;
}