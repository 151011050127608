import { InfoIcon } from 'lucide-react';
import React from 'react';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { cn } from '@/lib/utils';
interface InfoAlertProps {
  title?: string;
  description?: string | React.ReactNode;
  background?: boolean;
  icon?: React.ReactNode;
  children?: React.ReactNode;
}
const classes = {
  background: 'bg-background'
};
export const InfoAlert = ({
  title,
  description,
  children,
  background = false,
  icon
}: InfoAlertProps) => <Alert className={cn('space-y-1', {
  [classes.background]: background
})}>
    {icon ?? <InfoIcon color="rgb(0, 30, 113)" className="h-4 w-4" />}
    <AlertTitle>{title}</AlertTitle>
    <AlertDescription>{description ?? children}</AlertDescription>
  </Alert>;