import Image from 'next/image';
import { formatPercentage } from '@/lib/number';
import { Table } from '@/lib/types/Table';
import { TableColumn } from '@/lib/types/TableColumn';
import { TableColumnCounts } from '@/lib/types/TableColumnCounts';
import { TableType } from '@/lib/types/TableType';
const IMAGE_ALT = 'Percentage Visualization';
function closestValue(num: number): number {
  const values = [20, 40, 60, 80, 100];
  let closest = values[0];
  for (let i = 1; i < values.length; i++) {
    if (Math.abs(num - values[i]) < Math.abs(num - closest)) {
      closest = values[i];
    }
  }
  return closest;
}
export const percentageVisualization = (value?: number | null, inverted = false) => {
  if (value === null || value === undefined) {
    return null;
  }
  const closestPercentageVisualization = closestValue(value);
  const path = inverted ? '/bars/inverted' : '/bars';
  return <div className="inline-flex items-center justify-center gap-2">
      <Image width={24} height={24} alt={IMAGE_ALT} src={`${path}/${closestPercentageVisualization}.svg`} />
      {formatPercentage(value)}
    </div>;
};
export function aggregateColumnTypes(columns: TableColumn[]): TableColumnCounts {
  return columns.reduce((acc: TableColumnCounts, item) => {
    if (item.datatype === 'string') {
      acc.string += 1;
    }
    if (item.datatype === 'int') {
      acc.integer += 1;
    }
    if (item.datatype === 'double' || item.datatype === 'float') {
      acc.decimal += 1;
    }
    if (['date', 'datetime', 'timestamp'].includes(item.datatype)) {
      acc.date += 1;
    }
    if (item.datatype === 'binary') {
      acc.binary += 1;
    }
    if (item.datatype === 'geospatial') {
      acc.geospatial += 1;
    }
    return acc;
  }, {
    string: 0,
    integer: 0,
    decimal: 0,
    date: 0,
    binary: 0,
    geospatial: 0
  });
}

/**
 * Returns counted table types for tables
 */
export function aggregateTableTypes(tables: Table[]) {
  return tables.flatMap(table => table.types).reduce((accumulator: Pick<TableType, 'id' | 'display_name'>[], currentType) => {
    if (!accumulator.some(type => type.id === currentType.id)) {
      accumulator.push({
        id: currentType.id,
        display_name: currentType.display_name
      });
    }
    return accumulator;
  }, []);
}