'use client';

import { AnimatePresence } from 'framer-motion';
import { Ellipsis } from 'lucide-react';
import { useSearchParams } from 'next/navigation';
import { useState } from 'react';
import classes from '@/components/Search/SearchSidebar/classes';
import { Reveal } from '@/components/Shared/Reveal';
import { Button } from '@/components/ui/button';
import type { Facet } from '@/lib/types/Facet';
import { SearchSidebarFacetItem } from './FacetItem';
import { FACET_MAX_ITEMS, sortFacets } from './helper';
interface SearchSidebarFacetProps {
  facetName: string;
  facet?: Facet;
}
export function SearchSidebarFacet({
  facet,
  facetName
}: SearchSidebarFacetProps) {
  const isFacetTruncatable = (facet?.items.length ?? 0) > FACET_MAX_ITEMS;
  const [collapse, setCollapse] = useState(isFacetTruncatable);
  const searchParams = useSearchParams();
  if (!facet) {
    return null;
  }
  const urlValues = searchParams.getAll(facetName);
  const sortedItems = [...facet.items].sort((a, b) => sortFacets(urlValues, a, b)).slice(0, collapse ? FACET_MAX_ITEMS : undefined);
  return <AnimatePresence>
      <Reveal>
        <div className={classes.facet}>
          {sortedItems.map(facetItem => <SearchSidebarFacetItem allowMultiselect={facetName === 'tags'} facetName={facetName} key={facetItem.display_name} facetItem={facetItem} />)}

          {collapse && sortedItems.length !== facet.items.length && <Button className="mt-5 font-heading uppercase text-primary" variant="ghost" onClick={() => setCollapse(false)}>
              <Ellipsis /> Show more ({facet.items.length - sortedItems.length})
            </Button>}
          {!collapse && isFacetTruncatable && <Button className="mt-5 font-heading uppercase text-primary" variant="ghost" color="primary" onClick={() => setCollapse(true)}>
              <Ellipsis /> Show less
            </Button>}
        </div>
      </Reveal>
    </AnimatePresence>;
}