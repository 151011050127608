'use client';

import { useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import classes from '@/components/Search/SearchSidebar/classes';
import { LOCAL_STORAGE_SEARCH_KEY } from '@/lib/config';
import { SearchResult } from '@/lib/types/SearchResult';
import { SearchSidebarFacet } from './Facet';
import { SearchSidebarInput } from './Input';
interface SearchSidebarProps {
  result: SearchResult | null;
  searchString: string;
}
export function SearchSidebar({
  result,
  searchString
}: SearchSidebarProps) {
  const [_, storeSearch] = useLocalStorage(LOCAL_STORAGE_SEARCH_KEY, searchString);

  /** Persist search string into localStorage */
  useEffect(() => {
    storeSearch(searchString);
  }, [searchString, storeSearch]);
  return <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.input}>
          <SearchSidebarInput />
        </div>

        <div className={classes.group}>
          <h4>Sources</h4>
          <SearchSidebarFacet facetName={'source_en'} facet={result?.facets.source_en} />
        </div>

        <div className={classes.group}>
          <h4>Countries</h4>
          <SearchSidebarFacet facetName={'country_en'} facet={result?.facets.country_en} />
        </div>

        <div className={classes.group}>
          <h4>Keywords</h4>
          <SearchSidebarFacet facetName={'tags'} facet={result?.facets.tags} />
        </div>
      </div>
    </div>;
}