const FORMAT_LOCALE = 'en-US';

/**
 * Format number
 * @param value
 */
export const formatNumber = (value: number) =>
  new Intl.NumberFormat(FORMAT_LOCALE).format(value);

/**
 * Format number into 0-2 decimal places and apply decimal styling
 * @param value
 */
export const formatDecimal = (value: number) =>
  new Intl.NumberFormat(FORMAT_LOCALE, {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(value);

/**
 * Format number into 0-2 decimal places and apply percent styling
 * @param value
 */
export const formatPercentage = (value: number) =>
  new Intl.NumberFormat(FORMAT_LOCALE, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(value / 100);

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}
