'use client';

import Image from 'next/image';
import { SyntheticEvent, useCallback, useState } from 'react';
import logger from '@/lib/logger';
import { cn } from '@/lib/utils';
const blurDataUrl = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTgiIGhlaWdodD0iNTgiIHZpZXdCb3g9IjAgMCA1OCA1OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxwYXRoIGQ9Ik0xNCAzOFYyMC41TDI4LjUgMjguNVY0NS41TDE0IDM4WiIgZmlsbD0iI0RGNUNGRiIvPgo8cGF0aCBkPSJNMjguNSAyOS41TDQzIDIxLjVMNDIuNSAzOEwyOC41IDQ1LjVWMjkuNVoiIGZpbGw9IiNERjVDRkYiLz4KPHBhdGggZD0iTTE0LjUgMjFMMjguNSAxM0w0Mi41IDIxTDI4IDI5TDE0LjUgMjFaIiBmaWxsPSIjREY1Q0ZGIi8+CjxwYXRoIGQ9Ik0yOC41IDBMNCAxNC41MTA5VjQzLjQ4NjJMMjguNSA1Ny45OTcxTDUzIDQzLjQ4NjJWMTQuNTEwOUwyOC41IDBaTTUwLjMwMDYgNDEuNjE1TDQzLjI1NDggMzcuNDQxOVYyMS44NDVMNTAuMzAwNiAxNy42NzE5VjQxLjYxNVpNMTQuODQ2NiAyMi40OTc1TDI3LjE2MTYgMjkuNzkxVjQ0LjczNTRMMTQuODQ2NiAzNy40NDEyVjIyLjQ5NzVaTTQwLjExODEgMjAuNTM0OUwyOC41IDI3LjQxNjZMMTYuNjcyIDIwLjQxMDlMMjguMjkwMSAxMy41M0w0MC4xMTgxIDIwLjUzNDlaTTI5LjgzNzYgMjkuNzkxTDQxLjczMTUgMjIuNzQ1NFYzNy40NDEyTDI5LjgzNzYgNDQuNDg3NVYyOS43OTFaTTQxLjYxODEgMTkuNjQ2OEwyOS4wNTA3IDEyLjIwMzJWMy40ODA3Mkw0OC45ODIgMTUuMjgzN0w0MS42MTgxIDE5LjY0NjhaTTI3LjUzMDIgMy43Mjg2N1YxMi4yMDMyTDE1LjE3MDUgMTkuNTI1N0w4LjAxNjU0IDE1LjI4MzdMMjcuNTMwMiAzLjcyODY3Wk0xMy4zMjYxIDIxLjU5N1YzNy40NDEyTDYuNzAwMTIgNDEuMzY2M1YxNy42NzE5TDEzLjMyNjEgMjEuNTk3Wk03LjcyNCA0Mi41Mzg3TDE0LjA4NjcgMzguNzY4N0wyNy4xNjE2IDQ2LjUxMjRWNTQuMDUyNEw3LjcyNCA0Mi41Mzg3Wk0yOS44NDEzIDQ2LjI2NDRMNDIuNDk3OSAzOC43Njc5TDQ5LjA3MzQgNDIuNjYyNkwyOS44MzkxIDU0LjA1MzFMMjkuODQxMyA0Ni4yNjQ0WiIgZmlsbD0id2hpdGUiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMCI+CjxyZWN0IHdpZHRoPSI0OSIgaGVpZ2h0PSI1OCIgZmlsbD0id2hpdGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQpIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+';
const classes = {
  base: 'bg-origin-content object-contain',
  busy: 'animate-pulse',
  fallback: 'bg-secondary-readable p-8'
};
export function FallbackedImage({
  src
}: {
  src: string;
}) {
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const onLoad = useCallback(() => {
    setLoading(false);
  }, []);
  const onError = useCallback(() => {
    logger.warn(`Image ${src} failed to load, using fallback.`);
    setError(true);
  }, [src]);
  return <Image unoptimized width={128} height={128} onLoad={onLoad} onError={onError} placeholder={blurDataUrl} className={cn(classes.base, {
    [classes.busy]: loading,
    [classes.fallback]: loading || error
  })} src={error ? blurDataUrl : src} alt="Related map image" />;
}