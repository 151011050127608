'use client';

import { useSearch } from '@/hooks/swr/useSearch';
import { createUrlSearchParamsFromSearchParams } from '@/lib/search';
import { SearchParams } from '@/lib/types/SearchParams';
import { SortDictionary } from '@/lib/types/SortOption';
import { SearchResults } from './SearchResults';
import { SearchSidebar } from './SearchSidebar';
import { SearchSkeleton } from './Skeleton';
interface SearchProps {
  sortOptions: SortDictionary | null;
  searchParams: SearchParams;
}
export function Search({
  sortOptions,
  searchParams
}: SearchProps) {
  const urlSearchString = createUrlSearchParamsFromSearchParams(searchParams).toString();
  const {
    data: searchResult,
    isLoading
  } = useSearch(urlSearchString);
  if (isLoading) {
    return <SearchSkeleton />;
  }
  return <>
      <SearchSidebar result={searchResult} searchString={urlSearchString} />
      <SearchResults params={searchParams} result={searchResult} sortOptions={sortOptions ?? {}} />
    </>;
}