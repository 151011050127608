'use client';

import { cx } from 'class-variance-authority';
import { AnimatePresence } from 'framer-motion';
import Link from 'next/link';
import { Reveal } from '@/components/Shared/Reveal';
import { TableTypeIcon } from '@/components/Shared/TableTypeIcon';
import { aggregateTableTypes } from '@/components/Table/helper';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import type { Dataset } from '@/lib/types/Dataset';
import { FallbackedImage } from './FallbackedImage';
interface SearchResultsListItemProps {
  item: Dataset;
  map: string;
}
const classes = {
  truncated: 'line-clamp-6'
} as const;
export function SearchResultsListItem({
  item,
  map
}: SearchResultsListItemProps) {
  const aggregatedTypes = aggregateTableTypes(item.tables);
  return <AnimatePresence>
      <Reveal>
        <Card>
          <CardHeader>
            <div className="border border-[#acc2ff47] shrink-0 w-32 h-32">
              <FallbackedImage src={map} />
            </div>
            <Link prefetch={false} className="text-xl tracking-wide text-secondary no-underline" href={`/dataset/${item.name}/${item.tables?.[0].name}/summary`}>
              <CardTitle>{item.title.en}</CardTitle>
            </Link>
          </CardHeader>
          <CardContent>
            <div className="flex flex-col gap-7 pb-5">
              <div className="flex flex-col sm:flex-row gap-10">
                <div className="w-full sm:w-6/12">
                  <h5 className="mb-4">Source</h5>
                  <p className="text-sm">{item.source.display_name.en}</p>
                </div>

                <div className="w-full sm:w-6/12">
                  <h5 className="mb-4">Keywords</h5>
                  {item.tags.length > 0 && <div className="flex text-sm gap-2 [overflow-wrap:anywhere] text-balance">
                      {item.tags.map(tag => tag.display_name.en).join(', ')}
                    </div>}
                  {item.tags.length === 0 && <div className="flex text-sm gap-2 [overflow-wrap:anywhere] text-balance">
                      No keywords
                    </div>}
                </div>
              </div>
              <div className="flex flex-col sm:flex-row gap-10">
                <div className="w-full sm:w-6/12">
                  <h5 className="mb-4">Description</h5>
                  <p className="text-sm text-pretty [overflow-wrap:anywhere]">
                    <span className={cx(classes.truncated)}>
                      {item.description.plaintext.en}
                    </span>
                  </p>
                </div>
                <div className="w-full sm:w-6/12">
                  <h5 className="mb-4">Tables ({item.tables.length})</h5>
                  <ul className="flex flex-col gap-2">
                    {aggregatedTypes.length > 0 && aggregatedTypes.map(type => <li className="flex gap-2 text-sm items-center" key={`table-${item.id}-agg-${type.id}`}>
                          <span className="text-secondary mr-2">
                            <TableTypeIcon type={type.id} />
                          </span>
                          <span>{type.display_name.en}</span>
                        </li>)}
                  </ul>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </Reveal>
    </AnimatePresence>;
}