'use client';

import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import { useCallback } from 'react';
import { Badge } from '@/components/ui/badge';
import { FacetItem } from '@/lib/types/FacetItem';
import { isFacetItemActive, parseFacetQuery } from './helper';
interface SearchSidebarFacetItemProps {
  facetName: string;
  facetItem: FacetItem;
  allowMultiselect: boolean;
}
export function SearchSidebarFacetItem({
  facetName,
  facetItem,
  allowMultiselect = false
}: SearchSidebarFacetItemProps) {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const facetValue = facetItem.display_name;
  const existingValues = searchParams.getAll(facetName);
  const isActive = isFacetItemActive(existingValues, facetItem);
  const createQueryString = useCallback(() => parseFacetQuery(allowMultiselect, searchParams, {
    name: facetName,
    value: facetValue
  }), [allowMultiselect, facetName, searchParams, facetValue]);
  const linkText = facetItem.display_name.replaceAll('_', ' ');
  const linkHref = `${pathname}?${createQueryString()}`;
  return facetItem && <Link scroll={false} prefetch={false} className="max-w-[100%] inline-flex no-underline" href={linkHref}>
        <Badge size="default" variant={isActive ? 'active' : 'default'} title={`${linkText} (${facetItem.count})`}>
          <span className="grow-0 whitespace-nowrap text-ellipsis overflow-hidden">
            {linkText}
          </span>
          <span>({facetItem.count})</span>
        </Badge>
      </Link>;
}