/**
 * Control api logging
 */
const LOGGING_ENABLED = process.env.LOGGING_ENABLED === 'true';
const voidFn = () => {};

type Logger = Pick<Console, 'warn' | 'error' | 'info' | 'log' | 'debug'>;

/**
 * Simple logging abstraction
 */
const logger: Logger = {
  warn: LOGGING_ENABLED ? console.warn : voidFn,
  error: LOGGING_ENABLED ? console.error : voidFn,
  info: LOGGING_ENABLED ? console.info : voidFn,
  log: LOGGING_ENABLED ? console.log : voidFn,
  debug: LOGGING_ENABLED ? console.debug : voidFn,
};

export default logger;
