export const ROUTE_HREFS = {
  search: '/search',
  about: '/about',
} as const;

export const ROUTE_DESCRIPTIONS: Record<keyof typeof ROUTE_HREFS, string> = {
  search:
    'Our data catalog contains thousands of public, open and proprietary datasets from a large number of sources from all over the world.',
  about:
    'Information about a Smart Data Hub. Our story, vision, and values in brief.',
} as const;
