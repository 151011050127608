'use client';

import { ArrowDownWideNarrow, ArrowUpWideNarrow } from 'lucide-react';
import { SORT_DIRECTIONS } from '@/lib/search';
export function DirectionIcon({
  direction
}: {
  direction: (typeof SORT_DIRECTIONS)[number];
}) {
  if (direction === 'desc') {
    return <ArrowDownWideNarrow className="inline w-4 h-4" />;
  }
  if (direction === 'asc') {
    return <ArrowUpWideNarrow className="inline w-4 h-4" />;
  }
  return null;
}