'use client';

import { ChevronLeft, ChevronRight } from 'lucide-react';
import Link from 'next/link';
import { DEFAULT_SEARCH_PARAMS, PAGE_DEFAULT, ROWS_DEFAULT, calculateSearchStart, createUrlSearchParamsFromSearchParams } from '@/lib/search';
import { SearchParams } from '@/lib/types/SearchParams';
interface SearchResultsControlsProps {
  params: SearchParams;
  result_count: number;
}
const classes = {
  link: 'p-2',
  icon: 'text-[#002eaf66] transition-colors hover:text-secondary-readable h-8 w-8'
};
export function SearchResultsControls({
  params = DEFAULT_SEARCH_PARAMS,
  result_count
}: SearchResultsControlsProps) {
  const searchParams = Object.keys(params).length > 0 ? params : DEFAULT_SEARCH_PARAMS;
  const page = Number(searchParams?.page ?? PAGE_DEFAULT);
  const rows = Number(searchParams?.rows ?? ROWS_DEFAULT);
  const start = calculateSearchStart(page, rows);
  const resultText = `Displaying ${Math.max(start, 1)}-${Math.min(start + rows, result_count)} of ${result_count}`;
  const minPage = 0;
  const maxPage = Math.ceil(result_count / rows) - 1;
  const canGoBack = page > minPage;
  const canGoForward = page < maxPage;
  const prevPage = createUrlSearchParamsFromSearchParams({
    ...params,
    page: (Number(params.page ?? 0) - 1).toString()
  });
  const nextPage = createUrlSearchParamsFromSearchParams({
    ...params,
    page: (Number(params.page ?? 0) + 1).toString()
  });
  return <div className="flex flex-col lg:flex-row items-center gap-2 md:gap-10">
      <div className="flex items-center justify-center gap-3 mt-5 lg:mt-0">
        {canGoBack && <Link href={`/search?${prevPage}`} className={classes.link} scroll={false} prefetch={false}>
            <ChevronLeft className={classes.icon} />
          </Link>}
        <div className="tracking-tight leading-6 text-sm font-mono font-normal">
          {resultText}
        </div>
        {canGoForward && <Link href={`/search?${nextPage}`} className={classes.link} scroll={false} prefetch={false}>
            <ChevronRight className={classes.icon} />
          </Link>}
      </div>
    </div>;
}