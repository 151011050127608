import { ReadonlyURLSearchParams } from 'next/navigation';

import { FacetItem } from '@/lib/types/FacetItem';

interface HandleClickParams {
  name: string;
  value?: string | null;
}

/**
 * Amount facet items before they're collapsed
 */
export const FACET_MAX_ITEMS = 10;

export const hasStringValue = (values: string[], value: string) =>
  values.some((item) => [value, `"${value}"`].includes(item));

export const sortFacets = (urlValues: string[], a: FacetItem, b: FacetItem) => {
  const isSelectedA = hasStringValue(urlValues, a.name);
  const isSelectedB = hasStringValue(urlValues, b.name);

  return (
    Number(isSelectedB) - Number(isSelectedA) ||
    b.count - a.count ||
    a.display_name.localeCompare(b.display_name)
  );
};

export const isQuoteWrappedValueEqual = (
  wrappedValue: string,
  unwrappedValue: string,
) => wrappedValue === `"${unwrappedValue}"`;

export const isFacetItemActive = (
  existingValues: string[],
  facetItem: FacetItem,
) =>
  !!existingValues &&
  existingValues.length > 0 &&
  existingValues.some((value) =>
    isQuoteWrappedValueEqual(value, facetItem.display_name),
  );

export const parseFacetQuery = (
  allowMultiselect: boolean,
  searchParams: ReadonlyURLSearchParams,
  { name, value }: HandleClickParams,
) => {
  const urlValue = `"${value}"`;
  const params = new URLSearchParams(searchParams);
  const values = params.getAll(name);
  const hasValue = values.includes(urlValue);

  if (hasValue) {
    params.delete(name, urlValue);
  } else {
    params.set('page', '0');
    allowMultiselect
      ? params.append(name, urlValue)
      : params.set(name, urlValue);
  }

  return params.toString();
};
