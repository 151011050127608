'use client';

import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useCallback } from 'react';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { PAGE_DEFAULT, ROWS_DEFAULT, ROW_OPTIONS } from '@/lib/search';
export function SearchRowOptions() {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const createQueryString = useCallback((name: string, value?: string | null) => {
    const params = new URLSearchParams(searchParams);
    if (value) {
      params.set('page', PAGE_DEFAULT.toString());
      params.set(name, value);
    } else {
      params.delete(name);
    }
    return params.toString();
  }, [searchParams]);
  return <div>
      <Label htmlFor="rows">Rows</Label>
      <Select onValueChange={value => router.push(pathname + '?' + createQueryString('rows', value))} defaultValue={searchParams.get('rows') ?? ROWS_DEFAULT.toString()}>
        <SelectTrigger id="rows" className="w-full sm:w-24">
          <SelectValue placeholder="Sort" />
        </SelectTrigger>
        <SelectContent>
          {ROW_OPTIONS.map(item => <SelectItem key={`rows-${item}`} value={item.toString()}>
              {item}
            </SelectItem>)}
        </SelectContent>
      </Select>
    </div>;
}