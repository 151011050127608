import { VariantProps, cva } from 'class-variance-authority';
import { ArrowLeftRight, Grid2X2, Grid3X3, History, MapPin, Sigma } from 'lucide-react';
import { cn } from '@/lib/utils';
interface TableTypeIconProps {
  type: string;
}
const tableTypeVariants = cva('', {
  variants: {
    size: {
      default: '',
      sm: 'h-4 w-4'
    }
  },
  defaultVariants: {
    size: 'default'
  }
});

/**
 * Responsible for rendering table type icon in various places
 * Icons https://lucide.dev/icons/categories
 */
export const TableTypeIcon = ({
  type,
  size
}: TableTypeIconProps & VariantProps<typeof tableTypeVariants>) => {
  const props = {
    className: cn(tableTypeVariants({
      size
    }))
  };
  switch (type) {
    case 'history':
      {
        return <History {...props} />;
      }
    case 'fact':
      {
        return <Sigma {...props} />;
      }
    case 'dimension':
      {
        return <Grid3X3 {...props} />;
      }
    case 'transaction':
      {
        return <ArrowLeftRight {...props} />;
      }
    case 'geospatial':
      {
        return <MapPin {...props} />;
      }
    default:
      {
        return <Grid2X2 {...props} />;
      }
  }
};