'use client';

import { SearchParams } from '@/lib/types/SearchParams';
import { SearchResult } from '@/lib/types/SearchResult';
import { SortDictionary } from '@/lib/types/SortOption';
import { SearchResultsControls } from './Controls';
import { SearchRowOptions } from './RowOptions';
import { SearchSortOptions } from './SortOptions';
interface SearchResultsHeaderProps {
  sortOptions: SortDictionary | null;
  result: SearchResult | null;
  params: SearchParams;
}
export function SearchResultsHeader({
  sortOptions,
  result,
  params
}: SearchResultsHeaderProps) {
  return <div className="flex flex-col lg:flex-row justify-between mb-5">
      <div className="flex flex-col sm:flex-row justify-center lg:justify-start gap-5">
        <SearchRowOptions />
        <SearchSortOptions sortOptions={sortOptions ?? {}} />
      </div>
      <SearchResultsControls result_count={result?.result_count ?? 0} params={params} />
    </div>;
}