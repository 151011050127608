'use client';

import { useRouter, useSearchParams } from 'next/navigation';
import { useState } from 'react';
import { useDebounceValue } from 'usehooks-ts';
import { Input } from '@/components/ui/input';
import { ROUTE_HREFS } from '@/lib/navigation';
const SEARCH_INPUT_DELAY = 500;
const useSearchQuery = () => {
  const searchParams = useSearchParams();
  const [query, setQuery] = useState(searchParams.get('query') ?? undefined);
  const [debouncedQuery] = useDebounceValue<string | undefined>(query, SEARCH_INPUT_DELAY);
  return {
    query,
    debouncedQuery,
    setQuery
  };
};
const useInputUrlState = (query: string = '') => {
  const searchParams = useSearchParams();
  const router = useRouter();
  const urlQuery = searchParams.get('query') ?? '';
  if (urlQuery !== query) {
    console.log(query, urlQuery);
    const urlParams = new URLSearchParams(searchParams);
    urlParams.delete('tags');
    urlParams.delete('country_en');
    urlParams.delete('source_en');
    urlParams.set('page', '0');
    urlParams.set('query', query ?? '');
    urlParams.set('sort', 'score desc');
    router.push(`${ROUTE_HREFS['search']}?${urlParams.toString()}`);
  }
};
export function SearchSidebarInput() {
  const {
    query,
    debouncedQuery,
    setQuery
  } = useSearchQuery();
  useInputUrlState(debouncedQuery);
  return <Input autoFocus id="search-input" value={query} onChange={event => setQuery(event.target.value)} placeholder="Filter by keyword" />;
}